import { Box } from '@mui/material';
import { CreateLadderRequest, LadderSide } from '@protos/ladders';
import { addSecondsToNow } from '@utils/date';
import { CustomCellEditorProps } from 'ag-grid-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLaddersApi } from '../hooks/useLaddersApi';
import NumberInput from './NumberInput';

export const OrderInputCellEditor = (props: CustomCellEditorProps) => {
  const { createLadder } = useLaddersApi();
  const bidInputRef = useRef<HTMLDivElement>(null);
  const offerInputRef = useRef<HTMLDivElement>(null);
  const { bid, offer } = props.value ? props.value : { bid: '', offer: '' };

  const [bidValue, setBidValue] = useState<string>(bid);
  const [offerValue, setOfferValue] = useState<string>(offer);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Tab') {
        event.preventDefault();

        const bidInputElement = bidInputRef.current?.querySelector('input');
        const offerInputElement = offerInputRef.current?.querySelector('input');

        if (event.target === bidInputElement) {
          offerInputElement?.focus();
          offerInputElement?.select();
        } else if (event.target === offerInputElement) {
          bidInputElement?.focus();
          bidInputElement?.select();
        }
      } else if (event.key === 'Enter' && props.colDef.field) {
        const dataKeyToUpdate = props.colDef.field;

        const newRow = { ...props.node.data, [dataKeyToUpdate]: { bid: +bidValue, offer: +offerValue } };

        props.api.applyTransactionAsync({
          update: [newRow],
        });
        props.stopEditing();

        const ladderCreateRequestPayload: CreateLadderRequest = {
          symbol: dataKeyToUpdate === 'value' ? `${newRow.symbol}${newRow.id}` : `${newRow.symbol}${newRow.id}-${newRow.symbol}${dataKeyToUpdate}`,
          expiry_timestamp: addSecondsToNow(+newRow.validity),
          orders: [
            { price: +bidValue, side: LadderSide.BUY, amount: +newRow.size, hidden: false },
            { price: +offerValue, side: LadderSide.SELL, amount: +newRow.size, hidden: false },
          ],
          account_id: props.node.data.account_id || '',
        };

        createLadder(ladderCreateRequestPayload);
      }
    },
    [props, bidValue, offerValue]
  );

  useEffect(() => {
    if (!props.value) return;

    const { bid, offer } = props.value;
    setBidValue(bid);
    setOfferValue(offer);
  }, [props.value]);

  useEffect(() => {
    const inputElement = bidInputRef.current?.querySelector('input');
    inputElement?.focus();
    inputElement?.select();
  }, []);

  return (
    <Box display="flex" width="100%" height="100%">
      <NumberInput
        ref={bidInputRef}
        value={bidValue.toString()}
        onChange={(val: number | undefined) => setBidValue(val?.toString() || '')}
        placeholder="Bid"
        onKeyDown={onKeyDown}
        min={-99999}
        sx={{
          '& .MuiInputBase-input': {
            color: '#4189E8',
            fontSize: '11px',
            '--Input-minHeight': '0',
          },
          '& .MuiInputBase-root': {
            borderRadius: '0px',
            height: 25,
          },
        }}
      />
      <NumberInput
        ref={offerInputRef}
        value={offerValue.toString()}
        onChange={(val: number | undefined) => setOfferValue(val?.toString() || '')}
        placeholder="Offer"
        min={-99999}
        onKeyDown={onKeyDown}
        sx={{
          '& .MuiInputBase-input': {
            color: '#CD4B4B',
            fontSize: '11px',
            '--Input-minHeight': '0',
          },
          '& .MuiInputBase-root': {
            borderRadius: '0px',
            height: 25,
          },
        }}
      />
    </Box>
  );
};
