import { DownloadCSV } from '@features/admin/trading/components/DownloadCSV';
import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Box, Stack, styled } from '@mui/material';
import { FilterPanel } from '@shared/components/FilterPanel';
import { useDataLoader } from '@shared/hooks/useDataLoader';
import { Filter } from '@shared/protos/filter';
import { DataApi } from '@shared/utils/data';
import { AG_GRID_LICENCE_KEY } from '@utils/grid';
import { BodyScrollEndEvent, GridApi, GridReadyEvent, LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

export interface BlotterGridRef<T> {
  getGridApi: () => GridApi<T> | null;
}

interface BlotterWidgetProps<T> extends AgGridReactProps<T> {
  dataLoader: DataApi<T>;
  dataTransformer?: (data: T[]) => any[];
  filters?: Filter[];
  disableSearchFilter?: boolean;
  blotterTitle?: string;
  onRefreshClicked?: () => void;
  getGridApi?: (gridApi: GridApi<T>) => void;
  externalSearchValue?: string;
  maxHeight?: number;
  csvDownloadUrl?: string;
}

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100vw - 26px)',
    height: 'calc(100vh - 250px)',
  },
}));

LicenseManager.setLicenseKey(AG_GRID_LICENCE_KEY);

export const BlotterWidget = <T,>({
  filters = [],
  dataLoader,
  disableSearchFilter = false,
  blotterTitle,
  onRefreshClicked,
  getGridApi,
  externalSearchValue,
  dataTransformer,
  maxHeight,
  csvDownloadUrl,
  ...gridProps
}: BlotterWidgetProps<T>) => {
  const gridApiRef = useRef<GridApi<T> | null>(null);

  const { urlParams, setURLSearchParam } = useURLSearchParams();
  const { data, fetchMoreData, fetchData } = useDataLoader(dataLoader);

  const hasURLParamsFilters = useMemo(() => filters.length > 0, [filters]);

  const onBlotterScrollEnd = useCallback(
    (event: BodyScrollEndEvent<T, any>) => {
      const { direction, api } = event;
      if (direction === 'vertical' && api.getLastDisplayedRowIndex() === data.length - 1) {
        fetchMoreData();
      }
    },
    [data, fetchMoreData]
  );

  const onGridReady = useCallback((params: GridReadyEvent<T, any>) => {
    gridApiRef.current = params.api;
    getGridApi?.(params.api);
  }, []);

  useEffect(() => {
    const { search: urlParamSearch, ...urlParamFilters } = urlParams;
    const searchValue = urlParamSearch || externalSearchValue || '';

    if (hasURLParamsFilters) fetchData(urlParamFilters, searchValue);
    else fetchData();
  }, [externalSearchValue, urlParams, fetchData, hasURLParamsFilters]);

  return (
    <Stack direction="column" flex={1} position="relative" sx={{ width: '100%', height: '100%' }}>
      {!!filters.length && (
        <FilterPanel
          title={blotterTitle}
          filters={filters}
          onSearch={
            !externalSearchValue
              ? (val: string) => {
                  setURLSearchParam('search', val);
                }
              : () => {}
          }
          disableSearch={disableSearchFilter || !!externalSearchValue}
          onRefreshClicked={onRefreshClicked}
        >
          {csvDownloadUrl && <DownloadCSV csvUrl={csvDownloadUrl} style={{ marginLeft: '10px' }} />}
        </FilterPanel>
      )}
      <StyledBox className="ag-theme-balham-dark custom-grid">
        <AgGridReact<T>
          autoSizeStrategy={{ type: 'fitGridWidth' }}
          suppressDragLeaveHidesColumns
          suppressColumnMoveAnimation
          {...gridProps}
          defaultColDef={{ ...(gridProps.defaultColDef || {}), suppressHeaderMenuButton: true }}
          rowData={dataTransformer ? dataTransformer(data) : data}
          onBodyScrollEnd={onBlotterScrollEnd}
          tooltipShowDelay={0}
          onGridReady={onGridReady}
          domLayout={maxHeight ? 'normal' : 'autoHeight'}
          containerStyle={{ height: maxHeight ?? '100%', width: '100%' }}
          statusBar={{ statusPanels: [{ statusPanel: 'agAggregationComponent' }] }}
          cellSelection
        />
      </StyledBox>
    </Stack>
  );
};
