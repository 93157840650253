import { useStreamOrders } from '@hooks/useStreamOrders';
import { Order } from '@protos/trading';
import { priceFormatterService } from '@services/PriceFormatterService';
import { BlotterWidget } from '@shared/components/BotterWidget';
import { GridApi } from 'ag-grid-enterprise';
import { useCallback, useMemo, useState } from 'react';
import { ALL_ORDERS_GRID_COLUMNS } from '../constants/AllOrdersGridConsts';
import { useLaddersApi } from '../hooks/useLaddersApi';

export const AllOrdersGrid = () => {
  const { getAllOrdersLoader } = useLaddersApi();

  const [isReadyToSubscribe, setIsReadyToSubscribe] = useState<boolean>(false);
  const [blotterApi, setBlotterApi] = useState<GridApi<Order>>();

  const dataLoader = useMemo(() => getAllOrdersLoader(), [getAllOrdersLoader]);

  const onGetGridApi = useCallback((gridApi: GridApi<Order>) => {
    if (gridApi) {
      setBlotterApi(gridApi);
    }
  }, []);

  const onDataRendered = useCallback(() => {
    setIsReadyToSubscribe(true);
  }, []);

  const onStreamOrder = useCallback(
    (order: Order) => {
      if (!blotterApi) return;

      const isRowInGrid = !!blotterApi.getRowNode(order.id.toString());
      const transformedOrder: Order = {
        ...order,
        amount: priceFormatterService.removeTrailZeros(order.amount),
        executed_amount: order.executed_amount ? priceFormatterService.removeTrailZeros(order.executed_amount) : undefined,
        price: order.price ? priceFormatterService.removeTrailZeros(order.price) : undefined,
        executed_avg_price: order.executed_avg_price ? priceFormatterService.removeTrailZeros(order.executed_avg_price) : undefined,
      };
      blotterApi.applyTransactionAsync(isRowInGrid ? { update: [transformedOrder] } : { add: [transformedOrder] });

      setTimeout(() => {
        if (!isRowInGrid) {
          const newRowNode = blotterApi.getRowNode(order.id.toString());
          if (newRowNode) blotterApi.flashCells({ rowNodes: [newRowNode], fadeDuration: 2000 });
        }
      }, 200);
    },
    [blotterApi]
  );

  useStreamOrders(onStreamOrder, isReadyToSubscribe);

  if (!dataLoader) return null;

  return (
    <BlotterWidget
      getGridApi={onGetGridApi}
      dataLoader={dataLoader}
      columnDefs={ALL_ORDERS_GRID_COLUMNS}
      maxHeight={500}
      autoSizeStrategy={{ type: 'fitCellContents' }}
      onFirstDataRendered={onDataRendered}
    />
  );
};
