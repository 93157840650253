import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Box, Stack, styled } from '@mui/material';
import { ContractLadderResponse, LadderRequestSettings, LadderSide } from '@protos/ladders';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { AG_GRID_LICENCE_KEY } from '@utils/grid';
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  GridApi,
  GridReadyEvent,
  LicenseManager,
  NavigateToNextCellParams,
  ValueFormatterParams,
  ValueParserParams,
} from 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLaddersApi } from '../../hooks/useLaddersApi';
import { OrderInputCellEditor } from '../OrderInputCellEditor';
import { OrderInputCellRenderer } from '../OrderInputCellRenderer';
import { SpreadSettings } from './SpreadSettings';

const NO_CONTENT_CELL_CLASS = 'no-content';

const TENOR_COLUMN: ColDef<any> = {
  headerName: '',
  field: 'name',
  width: 100,
  sortable: false,
  pinned: 'left',
  lockPosition: 'left',
  cellClass: (params: CellClassParams) => {
    if (params.data.name.includes('JUN') || params.data.name.includes('DEC')) {
      return 'ag-tenor-column june-dec-cell';
    }

    return 'ag-tenor-column';
  },
};

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100vw - 26px)',
    height: 'calc(100vh - 250px)',
  },
}));

interface RowData {
  [key: string]: { bid: string; offer: string } | string | number;
}
interface OCLGridProps {
  getGridApi?: (api: GridApi) => void;
  getSpreadSettings?: (settings: LadderRequestSettings) => void;
  selectedAccount: string;
}

LicenseManager.setLicenseKey(AG_GRID_LICENCE_KEY);

export const SpreadOCLGrid = ({ getGridApi, getSpreadSettings, selectedAccount }: OCLGridProps) => {
  const { tenors } = useUserProductsAndTenorsContext();
  const { fetchLaddersForContracts } = useLaddersApi();
  const { urlParams } = useURLSearchParams();
  const gridApiRef = useRef<GridApi | null>(null);

  const [spreadSettings, setSpreadSettings] = useState<LadderRequestSettings>({
    symbol: urlParams.spreadSymbol || '',
    validity: Number(urlParams.spreadValidity) || 10,
    size: Number(urlParams.spreadSize) || 10,
  });
  const [ladderData, setLadderData] = useState<ContractLadderResponse[]>([]);

  const first24MonthlyTenors = useMemo(() => tenors.filter(tenor => tenor.frequency === 'monthly').slice(0, 24), [tenors]);
  const allVisibleTenors = useMemo(() => first24MonthlyTenors.slice(1, first24MonthlyTenors.length - 1), [first24MonthlyTenors]);
  const transformedRowData = useMemo(
    () =>
      allVisibleTenors.reduce((acc, baseTenor) => {
        const rowTenor = `${baseTenor.code}`;
        let newElement = {
          id: rowTenor,
          name: baseTenor.display.toLocaleUpperCase(),
          symbol: spreadSettings.symbol,
          validity: spreadSettings.validity,
          size: spreadSettings.size.toString(),
          account_id: selectedAccount,
        };

        allVisibleTenors.forEach((tailTenor, index) => {
          const columnTenor = `${tailTenor.code}`;
          const spreadContractSymbol = `${spreadSettings.symbol}${rowTenor}-${spreadSettings.symbol}${columnTenor}`;
          const foundLadder = ladderData.find(ladder => ladder.symbol === spreadContractSymbol);
          const allOrders = foundLadder?.orders;
          const bid = allOrders?.find(order => order.side === LadderSide.BUY)?.price;
          const offer = allOrders?.find(order => order.side === LadderSide.SELL)?.price;

          newElement = {
            ...newElement,
            [`${columnTenor}`]: { bid: bid ?? '', offer: offer ?? '' },
          };
        });

        acc.push(newElement);
        return acc;
      }, [] as RowData[]),
    [allVisibleTenors, spreadSettings, ladderData, selectedAccount]
  );

  const allGridColumns: (ColDef<any, any> | ColGroupDef<any>)[] = useMemo(
    () => [
      TENOR_COLUMN,
      ...first24MonthlyTenors.slice(2, first24MonthlyTenors.length - 1).map((tenor, index) => ({
        headerName: tenor.display.toLocaleUpperCase(),
        field: `${tenor.code}`,
        cellClass: params => {
          const columnIndex = first24MonthlyTenors.findIndex(elem => elem.display.toLocaleUpperCase() === params.colDef.headerName);

          if (
            (params.rowIndex !== 0 && columnIndex <= params.rowIndex) ||
            transformedRowData[params.rowIndex].name === first24MonthlyTenors[columnIndex].display.toLocaleUpperCase()
          ) {
            return NO_CONTENT_CELL_CLASS;
          }

          if (params.data.name.includes('JUN') || params.data.name.includes('DEC')) {
            return 'june-dec-cell';
          }

          return undefined;
        },
        sortable: false,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) {
            return params.value;
          }
        },
        valueParser: (params: ValueParserParams) => {
          if (params.newValue) {
            return { bid: params.newValue, offer: params.newValue };
          }

          return { bid: null, offer: null };
        },
        cellRenderer: OrderInputCellRenderer,
        cellEditor: OrderInputCellEditor,
        editable: () => true,
        minWidth: 120,
        width: 120,
      })),
    ],
    [first24MonthlyTenors]
  );

  const navigateToNextCell = useCallback((params: NavigateToNextCellParams<RowData, any>) => {
    const nextCell = params.nextCellPosition;
    if (nextCell) {
      const cellClass = nextCell.column.getColDef().cellClass;
      const cellClassParams = {
        ...nextCell.column,
        rowIndex: nextCell.rowIndex,
      } as unknown as CellClassParams;

      const isNoContent =
        typeof cellClass === 'string' || Array.isArray(cellClass)
          ? cellClass.includes(NO_CONTENT_CELL_CLASS)
          : cellClass?.(cellClassParams) === NO_CONTENT_CELL_CLASS;

      if (isNoContent) {
        return null;
      }
    }

    return nextCell;
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent<RowData, any>) => {
    gridApiRef.current = params.api;
    getGridApi?.(params.api);
  }, []);

  useEffect(() => {
    if (!spreadSettings?.symbol) return;

    getSpreadSettings?.(spreadSettings);
    fetchLaddersForContracts([spreadSettings.symbol]).then(allLadders => {
      setLadderData(allLadders);
    });
  }, [spreadSettings, fetchLaddersForContracts, getSpreadSettings]);

  if (!spreadSettings) return null;

  return (
    <Stack display="flex" flex={1} height="100%" gap={1}>
      <SpreadSettings spreadSettings={spreadSettings} setSpreadSettings={setSpreadSettings} />
      <StyledBox className="ag-theme-balham-dark ocl-grid">
        <AgGridReact
          autoSizeStrategy={{ type: 'fitCellContents', skipHeader: false }}
          defaultColDef={{ suppressKeyboardEvent: params => params.editing, suppressHeaderMenuButton: true }}
          suppressDragLeaveHidesColumns
          suppressColumnMoveAnimation
          rowData={transformedRowData}
          tooltipShowDelay={0}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          containerStyle={{ height: '100%', width: '100%' }}
          getRowId={({ data }): string => data?.id?.toString()}
          columnHoverHighlight={false}
          navigateToNextCell={navigateToNextCell}
          columnDefs={allGridColumns}
        />
      </StyledBox>
    </Stack>
  );
};
