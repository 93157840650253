import { sellRed } from '@features/dashboard/widgets/trading-v2/constants';
import { Typography } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { useEffect, useState } from 'react';

const formatSecondsIntoReadableTime = (seconds: number) => {
  const days = Math.floor(seconds / 86400);
  seconds %= 86400;

  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;

  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  const parts: string[] = [];
  if (days > 0) parts.push(`${days} d`);
  if (hours > 0) parts.push(`${hours} h`);
  if (minutes > 0) parts.push(`${minutes} m`);
  if (seconds > 0 || parts.length === 0) parts.push(`${seconds} s`);

  return parts.join(' ');
};

const CURRENT_TIMESTAMP = new Date().getTime();

export const DurationTimerCellRenderer = (params: ICellRendererParams) => {
  const { expiry_timestamp } = params.data;

  const expiryTimestamp = new Date(expiry_timestamp).getTime();

  const [timerDurationInSeconds, setTimerDurationInSeconds] = useState(Math.floor((expiryTimestamp - CURRENT_TIMESTAMP) / 1000));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimerDurationInSeconds(timerDurationInSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryTimestamp, timerDurationInSeconds]);

  if (!timerDurationInSeconds) return;
  if (timerDurationInSeconds < 0) return <Typography sx={{ color: sellRed, fontSize: '11px', fontWeight: 600 }}>Expired</Typography>;

  return <Typography sx={{ fontSize: '11px', fontWeight: 600 }}>{formatSecondsIntoReadableTime(timerDurationInSeconds)}</Typography>;
};
