import { Autocomplete, Box, Popper, Stack, styled, TextField, Typography } from '@mui/material';
import { LadderRequestSettings } from '@protos/ladders';
import { Product } from '@protos/product';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { useCallback, useEffect, useState } from 'react';
import NumberInput from '../NumberInput';

const SmallAutocomplete = styled(Autocomplete)({
  width: '125px',
  borderRadius: 0,
  '& .MuiAutocomplete-inputRoot': {
    padding: 0,
    fontSize: 11,
    height: 25,
    borderRadius: 0,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
    padding: 0,
  },
});

const StyledPopper = styled(Popper)({
  '& .MuiAutocomplete-listbox': {
    fontSize: 11,
  },
});

interface NonSpreadSettingsProps {
  nonSpreadSettings: LadderRequestSettings;
  setNonSpreadSettings: (settings: LadderRequestSettings) => void;
}

export const NonSpreadSettings = ({ nonSpreadSettings, setNonSpreadSettings }: NonSpreadSettingsProps) => {
  const { userProducts } = useUserProductsAndTenorsContext();

  const allNonSpreadProducts = userProducts.filter(product => ['swap', 'composite'].includes(product.product_type));
  const [selectedValue, setSelectedValue] = useState<Product>();

  const onProductChange = useCallback(
    (newProductValue: string) => {
      const product = allNonSpreadProducts.find(p => p.symbol === newProductValue);
      if (product) {
        setSelectedValue(product);
        setNonSpreadSettings({ ...nonSpreadSettings, symbol: product.symbol });
      }
    },
    [allNonSpreadProducts, setNonSpreadSettings, nonSpreadSettings]
  );

  const onValidityChange = useCallback(
    (value: number | undefined) => {
      if (!value) return;

      setNonSpreadSettings({ ...nonSpreadSettings, validity: value });
    },
    [nonSpreadSettings, setNonSpreadSettings]
  );

  const onSizeChange = useCallback(
    (value: number | undefined) => {
      if (!value) return;

      setNonSpreadSettings({ ...nonSpreadSettings, size: value });
    },
    [nonSpreadSettings, setNonSpreadSettings]
  );

  useEffect(() => {
    if (!allNonSpreadProducts.length) return;

    if (!selectedValue) {
      setSelectedValue(allNonSpreadProducts.find(p => p.symbol === nonSpreadSettings.symbol) || allNonSpreadProducts[0]);
    } else if (selectedValue.symbol !== nonSpreadSettings.symbol) {
      setNonSpreadSettings({ ...nonSpreadSettings, symbol: selectedValue.symbol });
    }
  }, [selectedValue, nonSpreadSettings, allNonSpreadProducts]);

  if (!selectedValue) return null;

  return (
    <Stack display="flex" flex={1} gap={2}>
      <Typography variant="h6" style={{ marginRight: 'auto' }}>
        {selectedValue.full_name}
      </Typography>
      <Box display="flex" flex={1} justifyContent="start" flexWrap="wrap">
        <NumberInput
          value={nonSpreadSettings.validity.toString()}
          onChange={onValidityChange}
          label="Validity (s)"
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '11px',
              '--Input-minHeight': '0',
            },
            '& .MuiInputBase-root': {
              borderRadius: '0px',
              height: 25,
            },
          }}
        />
        <NumberInput
          value={nonSpreadSettings.size.toString()}
          onChange={onSizeChange}
          label="Lots"
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '11px',
              '--Input-minHeight': '0',
            },
            '& .MuiInputBase-root': {
              borderRadius: '0px',
              height: 25,
            },
          }}
        />
        <SmallAutocomplete
          size="small"
          disableClearable
          options={allNonSpreadProducts}
          getOptionLabel={(option: unknown) => (option as Product).symbol.toUpperCase()}
          value={selectedValue}
          onChange={(_, newValue) => newValue && onProductChange((newValue as Product).symbol)}
          autoHighlight
          PopperComponent={props => <StyledPopper {...props} />}
          renderInput={params => <TextField {...params} variant="outlined" tabIndex={-1} />}
          isOptionEqualToValue={(option, value) => (option as Product).symbol === (value as Product).symbol}
          sx={{
            '& .MuiAutocomplete-input': { fontSize: 11, paddingLeft: 1.5 },
          }}
        />
      </Box>
    </Stack>
  );
};
