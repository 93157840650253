import { Order } from '@protos/trading';
import { priceFormatterService } from '@services/PriceFormatterService';
import { originalAmountFormatter } from '@utils/formatter';
import { ColDef } from 'ag-grid-enterprise';
import { DurationTimerCellRenderer } from '../components/DurationTimerCellRenderer';

export const ALL_ORDERS_GRID_COLUMNS: ColDef<Order>[] = [
  {
    field: 'side',
    headerName: 'Side',
    headerTooltip: 'Side',
    valueFormatter: ({ value }) => (value ? value.toLocaleString().toUpperCase() : ''),
    cellStyle: ({ value }) => ({ color: value.toLowerCase().includes('buy') ? '#4189E8' : '#CD4B4B' }),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    headerTooltip: 'Amount',
    valueFormatter: ({ value, data }) => (value ? originalAmountFormatter(value, data ? +data.original_amount : undefined) : ''),
  },
  {
    field: 'executed_amount',
    headerName: 'Exec. Amount',
    headerTooltip: 'Executedd Amount',
    valueFormatter: ({ value }) => (value ? value.toLocaleString() : ''),
  },
  {
    field: 'symbol',
    headerName: 'Symbol',
    headerTooltip: 'Symbol',
    valueFormatter: ({ value }) => (value ? (typeof value === 'string' ? value.toUpperCase() : `${value.front} / ${value.back}`.toUpperCase()) : ''),
  },
  {
    field: 'price',
    headerName: 'Price',
    headerTooltip: 'Price',
    valueFormatter: ({ value }) => (value ? priceFormatterService.formatProductPrice(value) : ''),
  },
  {
    field: 'executed_avg_price',
    headerName: 'Exec. Price',
    headerTooltip: 'Executed Average Price',
    valueFormatter: ({ value }) => (value ? priceFormatterService.formatProductPrice(value) : ''),
  },
  {
    field: 'order_type',
    headerName: 'Order Type',
    headerTooltip: 'Order Type',
    valueFormatter: ({ value }) => (value ? value.toLocaleString().toUpperCase() : ''),
  },
  { headerName: 'Expiry Duration Timer', headerTooltip: 'Expiry Duration Timer', cellRenderer: DurationTimerCellRenderer },
  { field: 'account_id', headerName: 'Account', headerTooltip: 'Account' },
];
