export interface ContractLadderResponse {
  symbol: string;
  expiry_timestamp: string;
  orders: any[];
  account_id: string;
  id: number;
  user_id: string;
  created_timestamp: string;
  is_live: boolean;
}

export enum LadderSide {
  BUY = 'buy',
  SELL = 'sell',
}

export interface LadderOrder {
  price: number;
  side: LadderSide;
  amount: number;
  hidden: boolean;
}

export interface CreateLadderRequest {
  symbol: string;
  expiry_timestamp: string;
  orders: LadderOrder[];
  account_id: string;
}

export interface LadderRequestSettings {
  symbol: string;
  validity: number;
  size: number;
}
