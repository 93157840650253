import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Box, Divider, Stack } from '@mui/material';
import { LadderRequestSettings } from '@protos/ladders';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useCallback, useEffect, useState } from 'react';
import { OCLGrid } from './components/non-spread/OCLGrid';
import { SpreadOCLGrid } from './components/spread/SpreadOCLGrid';
import { StyledAutocomplete } from './components/StyledAutocomplete';

interface AllSettings {
  symbol: string;
  validity: string;
  size: string;
}

interface AllSpreadSettings {
  spreadSymbol: string;
  spreadValidity: string;
  spreadSize: string;
}

export const AdvancedOCLTrading = () => {
  const user = useUserContext();
  const { urlParams, resetURLSearchParams, setURLSearchParam } = useURLSearchParams();

  const [selectedAccount, setSelectedAccount] = useState<string>('');
  const [allSettings, setAllSettings] = useState<AllSettings>();
  const [allSpreadSettings, setAllSpreadSettings] = useState<AllSpreadSettings>();

  const getNonSpreadSettings = useCallback(
    (settings: LadderRequestSettings) => {
      const { symbol, validity, size } = settings;

      if (allSettings && allSettings.symbol === symbol && allSettings.validity === validity.toString() && allSettings.size === size.toString())
        return;

      setAllSettings({ ...allSettings, symbol, validity: validity.toString(), size: size.toString() });
    },
    [allSettings]
  );

  const getSpreadSettings = useCallback(
    (settings: LadderRequestSettings) => {
      const { symbol, validity, size } = settings;

      if (
        allSpreadSettings &&
        allSpreadSettings.spreadSymbol === symbol &&
        allSpreadSettings.spreadValidity === validity.toString() &&
        allSpreadSettings.spreadSize === size.toString()
      )
        return;

      setAllSpreadSettings({ ...allSpreadSettings, spreadSymbol: symbol, spreadValidity: validity.toString(), spreadSize: size.toString() });
    },
    [allSettings]
  );

  useEffect(() => {
    if (!Object.keys(urlParams).length) {
      resetURLSearchParams({ ...allSettings, ...allSpreadSettings, account_id: selectedAccount });
    } else {
      if (!allSettings || !allSpreadSettings) return;

      const { symbol, validity, size, spreadSymbol, spreadValidity, spreadSize, account_id } = urlParams;
      if (symbol !== allSettings.symbol) setURLSearchParam('symbol', allSettings.symbol);
      if (validity !== allSettings.validity) setURLSearchParam('validity', allSettings.validity);
      if (size !== allSettings.size) setURLSearchParam('size', allSettings.size);
      if (spreadSymbol !== allSpreadSettings.spreadSymbol) setURLSearchParam('spreadSymbol', allSpreadSettings.spreadSymbol);
      if (spreadValidity !== allSpreadSettings.spreadValidity) setURLSearchParam('spreadValidity', allSpreadSettings.spreadValidity);
      if (spreadSize !== allSpreadSettings.spreadSize) setURLSearchParam('spreadSize', allSpreadSettings.spreadSize);
      if (account_id !== selectedAccount) setURLSearchParam('account_id', selectedAccount);
    }
  }, [urlParams, allSettings, allSpreadSettings, selectedAccount, resetURLSearchParams, setURLSearchParam]);

  useEffect(() => {
    if (selectedAccount || !user?.abn_trad_accounts.length) return;

    if (urlParams?.account_id && user.abn_trad_accounts.includes(urlParams.account_id)) {
      setSelectedAccount(urlParams.account_id);
    } else {
      setSelectedAccount(user.abn_trad_accounts[0]);
    }
  }, [urlParams, selectedAccount, user]);

  return (
    <Stack width="100%" height="100%" padding={1} gap={1}>
      <Box display="flex" flex={1} alignItems="center" justifyContent="end" sx={{ bgcolor: 'background.paper', padding: 1 }}>
        <Box minWidth="90%" />
        <StyledAutocomplete
          options={user?.abn_trad_accounts || []}
          getOptionLabel={(option: unknown) => (option as string).toUpperCase()}
          value={selectedAccount}
          onChange={(_, newValue) => newValue && setSelectedAccount(newValue as string)}
          label="Account"
          isOptionEqualToValue={(option, value) => (option as string).toUpperCase() === (value as string).toUpperCase()}
        />
      </Box>
      <Box display="flex" flex={1} width="100%" height="100%" gap={1} alignItems="start">
        <OCLGrid getNonSpreadSettings={getNonSpreadSettings} selectedAccount={selectedAccount} />
        <Divider orientation="vertical" flexItem />
        <SpreadOCLGrid getSpreadSettings={getSpreadSettings} selectedAccount={selectedAccount} />
      </Box>
    </Stack>
  );
};
